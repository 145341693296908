<template>
  <div class="billhead">
    <div class="myTable" v-for="(item, index) in myBill" :key="index">
      <div v-if="item.buyertype == 'C'">
        <div class="table-head">
          <p>类型：个人</p>
          <p>
            <span @click="updataBill(index)">
              <van-icon name="edit" />修改
            </span>
            <span @click="deleteBill(index)">
              <van-icon name="cross" />删除
            </span>
          </p>
        </div>
        <div class="table-content">
          <p>购方名称：{{ item.buyername }}</p>
          <p>识别码：{{ item.buyertaxpayernum }}</p>
          <p>手机：{{ item.buyertel }}</p>
          <p>Email：{{ item.buyeremail }}</p>
        </div>
      </div>
      <div v-if="item.buyertype == 'B'">
        <div class="table-head">
          <p>类型：公司</p>
          <p>
            <span @click="updataBill(index)">
              <van-icon name="edit" />修改
            </span>
            <span @click="deleteBill(index)">
              <van-icon name="cross" />删除
            </span>
          </p>
        </div>
        <div class="table-content">
          <p>购方名称：{{ item.buyername }}</p>
          <p>识别码：{{ item.buyertaxpayernum }}</p>
          <p>电话：{{ item.buyertel }}</p>
          <p>Email：{{ item.buyeremail }}</p>
          <p>地址：{{ item.buyeraddress }}</p>
          <p>开户银行：{{ item.buyerbankname }}</p>
          <p>账号：{{ item.buyerbankaccount }}</p>
        </div>
      </div>
    </div>
    <div>
      <van-button
        class="addbutton"
        color="#4a9be2"
        size="normal"
        @click="addBill"
        >新增</van-button
      >
    </div>
    <div v-if="isdata">
      <van-empty description="暂无数据" />
    </div>
    <div v-if="loading">
      <van-empty image="search" description="正在加载" />
    </div>
  </div>
</template>
<script>
import Event from '../util/event.js'
export default {
  data() {
    return {
      myBill: [],
      mobile: localStorage.getItem('mobile'),
      loading: true,
      isdata: false
    }
  },
  created() {
    let that = this
    this.$axios.get(`headlist?mobile=${this.mobile}`).then((res) => {
      if (res.data.retcode == '0000') {
        Event.$emit('creatBillHead')
        this.myBill = res.data.data
        this.loading = false
        if (this.myBill <= 0) {
          this.isdata = true
        }
      } else {
        this.$dialog
          .alert({
            message: res.data.retmessage
          })
          .then(() => {
            // on close
          })
      }
    })
  },
  methods: {
    backHomepage() {
      this.$router.push('/index')
    },
    addBill() {
      this.$router.push('/makeinvoice')
    },
    updataBill(index) {
      this.$router.push({
        path: '/updatainvoice',
        query: {
          updataObj: this.myBill[index],
          active: this.myBill[index].buyertype,
          mobile: this.mobile
        }
      })
    },
    deleteBill(index) {
      this.$dialog
        .confirm({
          message: '是否要删除' + this.myBill[index].buyername
        })
        .then(() => {
          this.$axios
            .delete(
              `head?mobile=${this.mobile}&infoid=${this.myBill[index].infoid}`
            )
            .then((res) => {
              if (res.data.retcode == '0000') {
                this.$dialog
                  .alert({
                    message: '删除成功'
                  })
                  .then(() => {
                    this.myBill.splice(index, 1)
                  })
              } else {
                his.$dialog
                  .alert({
                    message: res.data.retmessage
                  })
                  .then(() => {
                    // on close
                  })
              }
            })
        })
        .catch(() => {
          // on cancel
        })
    },
    updataComBill(index) {
      this.$router.push({
        path: '/updatainvoice',
        query: {
          updataObj: this.myBill[index],
          active: this.myBill[index].buyertype
        }
      })
    }
  }
}
</script>
<style scoped>
.billhead {
  min-height: 100%;
  margin-bottom: 150px;
}
.billhead .addbutton {
  width: 80%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 85px;
  margin: auto;
}
.billhead .myTable {
  border: 1px solid #ddd;
  margin: 15px;
}
.billhead .myTable .table-head {
  display: flex;
  justify-content: space-between;
  background: #428bca;
  line-height: 30px;
  color: #fff;
  padding: 0 15px;
}
.billhead .myTable .table-content {
  background: #eee;
  padding: 15px;
}
</style>